.chooseYourLanguage {
  position: absolute;
  top: 0;
  left: 89px;
  font-weight: 600;
}
.javaParent {
  position: absolute;
  top: 85px;
  left: 121px;
}
.chooseYourLanguageParent {
  position: absolute;
  top: 0;
  left: 3px;
  width: 457px;
  height: 170px;
}
.frameforforgetpass,
.pagecrossIcon {
  position: absolute;
  top: 78px;
  left: 9px;
  width: 486px;
  height: 170px;
}
.pagecrossIcon {
  top: 0;
  left: 443.5px;
  width: 7.5px;
  height: 7px;
  cursor: pointer;
}
.frameforcross {
  position: absolute;
  top: 32px;
  left: 21px;
  width: 451px;
  height: 7px;
  overflow: hidden;
}
.save {
  position: relative;
  font-weight: 600;
  display: inline-block;
  width: 92px;
  flex-shrink: 0;
}
.button,
.buttondone {
  position: absolute;
  border-radius: var(--br-xl);
  width: 138px;
  height: 38px;
  color:white;
  font-family: var(--font-poppins);
  font-size: medium;
}
.button {
  top: 0;
  left: 0;
  background: radial-gradient(50% 50%at 50% 50%, #a27cf3, #683fea);
  box-shadow: 0 0 180px #9917ff, 0 0 1px 4px rgba(255, 255, 255, 0.1),
    0-4px 2px rgba(0, 0, 0, 0.25) inset,
    0 2px 1px rgba(255, 255, 255, 0.25) inset;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-smi) var(--padding-31xl);
  box-sizing: border-box;
  cursor: pointer;
}
.button:active {
  background: linear-gradient(
      rgba(153, 117, 232, 0.91),
      rgba(153, 117, 232, 0.91)
    ),
    radial-gradient(50% 50%at 50% 50%, #a27cf3, #683fea);
}
.buttondone {
  top: 245px;
  left: calc(50% - 70.5px);
  text-align: center;
  font-size: 19px;
}
.enrollPopUp {
  position: relative;
  border-radius: var(--br-6xl);
  background-color: var(--color-gray-200);
  width: 497px;
  height: 341px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.error {
  border: 1px solid red;  /* Add any other styling you want for the error state */
}
