@keyframes shadow-drop-bottom {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  to {
    box-shadow: 0 12px 20px -12px rgb(0 0 0/35%);
  }
}
.interfacetutChild,
.interfacetutItem {
  position: absolute;
  top: -5px;
  left: 6px;

  width: 200px;
}

.interfacetutChild {
  border-radius: var(--br-6xl);
  background-color: var(--color-darkslategray-300);
  height: 240px;
  width: 238px;
}
.interfacetutItem {
  border-radius: var(--br-xl) var(--br-xl) 0 0;
  background: var(--tutorial-gradient);
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25);
  height: 57px;
  width: 238px; 
}
.interfaces,
.interfacesDefineA {
  position: absolute;
  display: inline-block;
}
.interfacesDefineA {
  top: 82px;
  left: 42px;
  width: 175px;
  height: 94px;
  text-align: center;
  color: var(--color-darkgray);
  letter-spacing: 0.02em;
  line-height: 110%;
  font-weight: 300;
}
.interfaces {
  top: 14px;
  left: 28px ;
  text-align: center;
  font-size: var(--font-size-sm);
  line-height: 110%;
  font-weight: 500;
  color: var(--color-white);
  width: 190px;
  height: 13px;
}
.interfacetut {
  position: absolute;
  top: 101px;
  left: 275px;
  width: 200px;
  height: 240px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-smi);
  color: var(--color-darkgray);
  font-family: var(--font-poppins);
}
/* .interfacetut:hover {
  filter: drop-shadow(0 4px 4px rgba(204, 47, 57, 0.7));
  animation: 1s ease 0s infinite normal none shadow-drop-bottom;
  opacity: 5;} */



  .interfacetut:hover {
    filter: drop-shadow(0 15px 30px var(--hover-color)); /* Adjust the spread-radius value as needed */
    /* Adjust the spread-radius value as needed */
    transform: scale(1.05);
    /* Increase the scale on hover */
    transition: transform 0.3s ease;
  }
  