.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  width: 497px;
  height: 241px;
  max-width: 100%;
  max-height: 100%;
  top: 200px;
  position: fixed;
  left: 500px;
  border-radius: var(--br-6xl);
  background-color: var(--color-gray-200);
  text-align: left;
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  color: white;
  border-radius: 19px;
  margin-left: 20px;
  position: relative;
  justify-content: center;
  align-items: center;

}



.pagecrossIcon {

  position: absolute;
  top: 0;
  left: 426px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.frameforcross {
  position: absolute;
  top: 32px;
  left: 21px;
  width: 451px;
  height: 14px;
  overflow: hidden;
}

.text {
  /* margin-top: 40px;*/
  position: absolute;
  margin-top: 15px;
  
  margin-left: 150px;
  
}

.languageButtons {
  padding: 8px 16px;
  font-size: 16px;
  border: none;
  background-color: #683fea;
  color: white;
  border-radius: 14px;
  cursor: pointer;
  box-shadow: 0 0 1px 1px #ffffff1a,inset 0-4px 2px #00000040;

  margin-left: 65px;
  margin-top: 120px;
  position: relative;

}

.languageButtons:hover {
  background-color: #2e205c;
  /* Darker shade of blue on hover */
}


.disabled {
  cursor: not-allowed;
  background-color: grey;
  color: #ffffff;
  /* Change the text color to white or any color that contrasts well with grey */
}

.disabled:hover {
  background-color: grey;
  /* Darker shade of blue on hover */
}