.mainjs {
  position: relative;
  font-weight: 500;
}
.file {
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-7xs) var(--padding-base);
  gap: var(--gap-9xs);
  top: 6px;
  height: 39px;
  cursor:pointer;

}
.file:hover {
  background-color: var(--white-transparent-10);
}

.file.selected {
  background-color: var(--white-transparent-10);
}

.code {
  top: 6px;
  left: 0;
  background-color: var(--color-gray-400);
  width: 548px;
  height: 33px;
}
.code {
  top: 40px;
  background-color: var(--color-gray-300);
  height: 610px;

}

code.layer-background .highlighted{
  background: rgba(255, 254, 254, 0.947);
  position: absolute;
  width: 100% !important;
  left: 0 !important;
}

.code.highlighted{
  background: rgba(255, 254, 254, 0.947);
  position: absolute;
  width: 100% !important;
  left: 0 !important;
}

code.layer.ace_marker-layer{
  background: rgba(255, 254, 254, 0.947);
  position: absolute;
  width: 100% !important;
}

code_layer.ace_marker-layer{
  background: rgba(255, 254, 254, 0.947);
  position: absolute;
  width: 100% !important;
}

.code.layer-background .highlighted{
  background-color: rgb(238, 4, 51);
}

.code.highlighted{
  background-color: rgb(238, 4, 51);
}

.highlighted.layer-background {
  background-color: rgb(238, 4, 51);
}

.highlighted_layer.ace_marker-layer{
  background: rgba(255, 254, 254, 0.947);
  position: absolute;
  width: 100% !important;
}

.code ::-webkit-scrollbar {
  height: 7px ;
  width: 7px; 
}

/* .code ::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  background-color: #272822; /* Matches ace monokai 
  border-radius: 10px ;
}

.code ::-webkit-scrollbar-thumb {
  background-color: rgb(181, 181, 181) ;
  outline: 1px solid slategrey;
  border-radius: 10px ;
} */

.code ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  background-color: transparent; /* Make scrollbar track transparent */
  border-radius: 10px;
}

/* Scrollbar Thumb */
.code ::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 1px solid rgb(17, 22, 27);
  border-radius: 10px;
}

.code ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(181, 181, 181) ;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.warning
{
    background: rgba(216, 87, 87, 0.977);
    position: absolute;
    width: 100% !important;
    left: 0 !important;
}

.highlighted{
  background-color: rgb(238, 4, 51);
}

/* 
.ace_marker.layer-background .highlighted-person {
  background-color: rgba(255, 255, 255, 0.3); /* Red color with transparency 
}

.ace_marker.layer-background .highlighted-manager {
  background-color: rgba(0, 255, 0, 0.3); /* Green color with transparency 
}

.ace_marker.layer-background .highlighted-employee {
  background-color: rgba(0, 0, 255, 0.3); /* Blue color with transparency 
} */

.outputWrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.iconLineclose {
  position: relative;
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.frameGroup {
  width: 492px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.topbarFiles {
  background-color: #201B3A;
}
.frameChild {
  align-self: stretch;
  height: 99px;
  overflow: hidden;
  flex-shrink: 0;
}
.frameParent,
.main {
  position: relative;
  left: 0;
}
.frameParent {
  top: 643px;
  border-top: 2px solid var(--color-darkslategray-300);
  box-sizing: border-box;
  width: 548px;
  height: 220px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-9xl);
  gap: var(--gap-3xs);
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  background-color: #120C31;
}
/* Adjust your existing .main component styles */
.main {
  position: relative; 
  width: 100%;
  right: 0;
  height: 865px;
  text-align: left;
  font-size: var(--font-size-sm);
  font-family: var(--font-poppins);
}

.objectify {
  position: relative;
  font-size: 13.5px;
  font-weight: 600;
  text-align: center;
  color: white;
  font-family: var(--font-poppins);
}
.icPlayArrow48pxIcon {
  position: relative;
  width: 15.4px;
  height: 15.4px;
  overflow: hidden;
  flex-shrink: 0;
}
.explorebutton {
  cursor: pointer;
  border: 0;
  padding: 4.3333330154418945px 15.166666030883789px;
  background-color: var(--color-darkorange);
  position: absolute;
  top: 9px;
  left: 420px;
  border-radius: 5.78px;
  width: 104.7px;
  height: 26px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 7.22px;
}
.explorebutton:hover{
background-color: var(--color-darkorange-onhover);

}
.vectorIcon,
.vectorIcon1 {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.vectorIcon1 {
  height:90.5%;
  width: 75.5%;
  top: 8.5%;
  right: 17%;
  bottom: 8%;
  left: 16.5%;
}

.js {
  position: absolute;
  top: 49%;
  left: 25%;
  line-height: 8px;
  font-size: xx-small;
  color: black;
}
.icon,
.mainjs {
  position: relative;
}
.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.mainjs {
  font-size: var(--font-size-sm);
  font-weight: 500;
  font-family: var(--font-poppins);

}
.main{
  left:90px;
}

.defaultOutputContainer{
  background-color: #1f1d22;
  z-index: 0;
  position: absolute;
  top: 3px ;
  bottom: 8px;
  left: 90px;
  width: 548px;
}
.customOutputContainer{

  top: 23px ;
  bottom: 8px;
  left: 0px;
  width: 548px;
}

.fileContainer, .frame {
  display: flex;
  max-width: 400px;
  overflow-x: auto;  /* Enable horizontal scrolling */
  white-space: nowrap; 
}

.fileContainer::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.fileContainer {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.pagecrossIcon {
  cursor: pointer;
margin-left:10px;
margin-right:-10px
}

/*Ace CSS*/
/* Specific styles for Ace Editor scrollbar */
.ace_scrollbar::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important; 
}

.ace_scrollbar::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  background-color: #272822 !important; /* Matches ace monokai */
  border-radius: 10px !important;
}

.ace_scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
  border-radius: 10px !important;
}

.ace_scrollbar-v::-webkit-scrollbar {
  height: 7px !important;
  width: 7px !important; 
}

.ace_scrollbar-v::-webkit-scrollbar-track
{
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
  background-color: #272822 !important; /* Matches ace monokai */
  border-radius: 10px !important;
}

.ace_scrollbar-v::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
  border-radius: 10px !important;
}

