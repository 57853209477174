
.voop {
  position: absolute;
  line-height: 6px;
}
.voop {
  top: 0;
  left: 84px;
  font-size: 90px;
  font-family: var(--font-righteous);
}
.middleinfo {
  display: flex;
  max-width: 100%;
  align-content: stretch;
}
.middleinfo {
  position: absolute;
  top: calc(50% - 102px);
  left: calc(50% - 197px);
  width: 100%;
  height: 264px;
  justify-content: center;
}

.wrapperFrame33656Child {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 60px;
  top: 0;
  transform: scale(2.313);
}
.wrapperFrame33656 {
  position: absolute;
  bottom: 568px;
  left: 0;
  width: 212px;
  height: 181px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.wrapperRectangle75Child {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(1.792);
}
.wrapperRectangle75 {
  position: absolute;
  top: 466px;
  left: 51px;
  border-radius: 51.55px;
  width: 101px;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.wrapperFrame33655Child {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 4px;
  transform: scale(1.122);
}
.wrapperFrame33655 {
  position: absolute;
  top: 46.7px;
  left: 53px;
  width: 472.6px;
  height: 408.4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapperFrame33654Child {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  left: 0;
  top: 50px;
  transform: scale(1.17);
}
.wrapperFrame33654 {
  position: absolute;
  top: 0;
  right: 65.1px;
  width: 294.9px;
  height: 747.2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frameChild {
  position: absolute;
  top: 411px;
  left: 249px;
  width: 948px;
  height: 374px;
  object-fit: cover;
}
.newToOop,
.ourSolution,
.ourTeamAims {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ourSolution {

  top: 0;
  left: 220px;
  font-size: 50px;
  font-family: var(--font-righteous);
}
.newToOop,
.ourTeamAims {
  top: 100px;
  left: 10px;
  width: 706px;
  height: 84px;
}

.text {
  position: relative;
  font-weight: 600;
}

.middleinfo1,

.wrapperFrame33655Parent {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  max-width: 100%;
  align-content: stretch;
}
.middleinfo1 {
  top: 300px;
  left: 250px;
  height: 504px;
}

.wrapperFrame33655Parent {
  top: 0;
  left: 0;
  height: 849px;
}
.thirdpage {
  top: 1708px;
  height: 843px;
  font-size: var(--font-size-xl);
}


.voopSeeMoreContainer {
  position: absolute;
  bottom: 32px;
  left: calc(50% - 54px);
  line-height: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 120px;
  height: 48px;
}
.Home {
  width: 100%;
  display: flex;
  max-width: 100%;
  text-align: center;
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.Home {
  position: relative;
  background: radial-gradient(
    50% 50%at 50% 50%,
    #5b3cb4 19.79%,
    #28128d 88.02%
  );
  height: 100%;
  overflow: hidden;
  justify-content: stretch;
  align-content: stretch;
  font-size: var(--font-size-11xl);
}
