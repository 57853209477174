.blankLine,
.voop {
  margin-block-start: 0;
  margin-block-end: 1px;
}
.blankLine {
  font-size: var(--font-size-base);
}
.seeMoreLearn {
  margin: 0;
  font-size: var(--font-size-5xs);
}
.voopSeeMoreContainer {
  position: absolute;
  top: 53.5px;
  left: 670.5px;
  line-height: 8px;
  font-weight: 500;
}
.footer {
  z-index: 2;
  background: #1b1a1f;
  width: 100%;
  height: 139px;
  display: flex;
  align-content: stretch;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
  
}

