.profileupdateconfirmationLChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background: linear-gradient(
    180deg,
    rgba(229, 240, 247, 0.28),
    rgba(229, 240, 247, 0.11) 81.83%,
    rgba(229, 240, 247, 0.07)
  );
  box-shadow: 0 4px 24px -4px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  width: 380px;
  height: 148px;
}
.updated {
  position: absolute;
  top: 15px;
  left: 147px;
  font-weight: 600;
}
.yourInformationHas {
  position: absolute;
  top: 61px;
  left: 44px;
  font-size: var(--font-size-xs);
}
.pagecrossIcon {
  position: absolute;
  top: 15px;
  left: 343px;
  width: 7.5px;
  height: 7px;
  cursor: pointer;
}
.profileupdateconfirmationL {
  position: relative;
  width: 380px;
  height: 148px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
