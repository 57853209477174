.dashbaordwelcChild {
  position: absolute;
  top: 0;
  left: 0;
  width: 1215px;
  height: 144px;
  /*object-fit: cover;*/
}
.dashbaordwelc,
.welcomeToYour {
  position: absolute;
  top: 47px;
  left: 51px;
}
.dashbaordwelc {
  top: 0;
  left: 0;
  width: 1215px;
  height: 144px;
}
.dashbaordwelcParent {
  position: absolute;
  top: 111px;
  left: 142px;
  width: 1221px;
  height: 831px;
  display: flex;
  /* overflow: hidden; */
}
.dashboard {
  position: relative;
  background: #221f2e;
  width: 100%;
  height: 1075px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-21xl);
  color: var(--color-white);
  font-family: var(--font-righteous);
}
.dashboard:hover {
  filter: drop-shadow(0 4px 4px 5px rgba(104, 63, 234, 0.25));
}


.continueLearning {
  position: absolute;
  top: 500px;
  left: 0px;
  font-weight: 600;
  font-size: var(--font-size-7xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.continueLearningText {
  position: absolute;
  top: 495px;
  left: 20px;
  font-weight: 600;
  font-size: var(--font-size-7xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.recentProjects {
  position: absolute;
  top: -4px;
  width: max-content;
  left: 1px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 100px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-weight: 600;
  font-size: var(--font-size-7xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
  
}

.pythonRecentprojectsChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs) var(--br-3xs) 0 0;
  background: linear-gradient(180deg, #e07410, #dfb48b);
  width: 300px;
  height: 233.6px;
}
.pythonRecentprojectsItem {
  position: absolute;
  top: 148.7px;
  left: 0;
  width: 300.2px;
  height: 85.5px;
  object-fit: cover;
}
.python {
  position: relative;
  line-height: 8px;
}
.lastUpdated7,
.pythonWrapper,
.shapesProj {
  position: absolute;
  display: flex;
  align-items: center;
}
.pythonWrapper {
  top: 30.4px;
  left: 21px;
  border-radius: var(--br-8xs);
  background-color: var(--color-white);
  width: 71px;
  height: 22px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-7xs);
  box-sizing: border-box;
  font-family: var(--font-pt-sans);
}
.lastUpdated7,
.shapesProj {
  left: 22px;
  line-height: 8px;
  justify-content: center;
}
.shapesProj {
  top: 74px;
  font-size: var(--font-size-mini);
  font-weight: 600;
  color: var(--color-white);
  width: 113px;
  height: 8px;
}
.lastUpdated7 {
  top: 95px;
  font-size: var(--font-size-3xs);
  color: var(--color-darkslategray-200);
  width: 107px;
  height: 11px;
}

.icon {
  /* position: absolute;
  top: calc(50% - 44.5px);
  left: calc(50% - 25px);
  width: 50px;
  height: 50px;
  object-fit: cover;
  cursor: pointer; */
  width: 35px; /* Set the width of the image */
  height: auto; /* Maintain aspect ratio */
  margin: auto; /* Center the image horizontally and vertically */

}
/* .newProject {
  position: absolute;
  top: 81px;
  left: 0;
  line-height: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  cursor: pointer;
} */
/* .iconParent,
.newproject {
  position: absolute;
  top: 775px;
  left: 105px;
  width: 110px;
  height: 89px;
  cursor: pointer;
} */
.newproject {
  position: absolute;
  top: 50px;
  left: 0px;
  right:0;
  display: flex;
  border-radius: var(--br-6xl);
  width: 230px;
  height: 230px;
  /* background: #5551ff;  */
/* 
  width: 200px;
  height: 200px; */
  font-size: var(--font-size-mini);
  flex-direction: column;
  align-items: center;
  justify-content:center;
  background: linear-gradient(131.42deg, #413c54, #343044 0.01%, #22222d);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4), 0 4px 4px rgba(0, 0, 0, 0.25) inset;
  
}
/* .Projects_addingboxChild__rxybs {
  position: relative;
  top: 0;
  left: 0;
  border-radius: var(--br-3xs);
  background: linear-gradient(131.42deg, #413c54, #343044 0.01%, #22222d);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4), 0 4px 4px rgba(0, 0, 0, 0.25) inset;
  width: 200px;
  height: 200px; */

.newproject:hover {
  /* background: #5551ff; Set the background color on hover to #5551ff */
   background-color: var(--color-midnightblue-400);

  /* Add any other styles you want for the hover effect */
  transform: scale(1.05);
  /* Increase the scale on hover */
  transition: transform 0.3s ease;
}
.recentprojects {
  position: absolute;
  top: 192px;
  left: 16px;
  width: 999px;
  height: 284px;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.cloudsIcon{
  /* Correcting the class name and targeting the img tag within it */
  position: absolute; /* Absolute position to move it freely */
  padding-left: 690px;
  right: 0; /* Align it to the right side */
  top: 50%; /* Center it vertically */
  transform: translateY(-50%); /* Offset by half its height to truly center it */
  border-radius: var(--br-mini); /* Your existing border radius */
  width: auto; /* Keep the width automatic */
  height: 144px; /* The height you wanted */
  object-fit: contain; /* Keep the aspect ratio */
 background:linear-gradient(180deg, #e47653, #553ba7);
 
}

