.identifiedOopConcept {
  position: absolute;
  height: auto;
  width: auto;
  left: 6% !important;
  top: 4px;
  font-weight: 500;
  font-family: 'POPPINS';
  margin-top: 3px;
  display: inline-block;
  background-color: var(--oop-concept-background);
  color: white;
}

.identifiedOopConceptTitle {
  position: absolute;
  height: auto;
  width: auto;
  padding: 3px 56px 3px 56px;
  left: 6%;
  top: 34px;
  border-radius: 13px;
  font-weight: 500;
  font-family: 'POPPINS';
  margin-top: 8px;
  display: inline-block;
  border: 2px solid var(--oop-concept-background);
  color: white;

}


.vectorIcon {
  top: 82.29%;
  right: 3.09%;
  bottom: 6.71%;
  left: 95.51%;
  transform: scaleX(-1);
  cursor: pointer;
}

.vectorIcon,
.vectorIcon1,
.vectorIcon2,
.vectorIcon3 {
  position: absolute;
  height: 11%;
  width: 1.4%;
  padding: 2px;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;

  /* background-color: rgb(234, 234, 237); */
  border-radius: 2px;
}

.vectorIcon:hover,
.vectorIcon1:hover,
.vectorIcon2:hover,
.vectorIcon3:hover {
  background-color: rgb(179, 179, 179);
}

.vectorIcon1 {
  top: 82px;
  right: 0;
  bottom: 6.71%;
  left: 95.6%;
  cursor: pointer;
  height: 10px;
  border-radius: 10px;
  background-color: #d8d4d4;
  margin-top: 8px;
}

.vectorIcon {
  top: 82px;
  left: 2.8%;
  height: 10px;
  cursor: pointer;
  border-radius: 10px;
  background-color: #d8d4d4;
  margin-top: 8px;

}

.vectorIcon2 {
  bottom: 80%;
  left: 8px;
  top: 5px;
  cursor: pointer;
  transform: scaleX(-1);
  background: #d8d4d4;
  height: 10px;
  width: 10px;
  border-radius: 10px;
}

.vectorIcon3 {
  bottom: 80%;
  left: 86.909% !important;
  cursor: pointer;
  /* transform: rotate(-270deg); */
  top: 5px;
  width: 10px;
  background: #d8d4d4;
  height: 10px;
  border-radius: 10px;
}

.div,
.theFirstClass {
  position: absolute;
  height: auto;
  width: 565px;
  font-family: 'POPPINS';
color: #d3d3d3;
  top: 235.14%;
  left: 43px;
  font-size: 11px;
  margin-top: 8px;
  line-height: 162.69%;
  display: inline-block;
  /* margin-top: 10px;*/
}

.div {
  width: 8.5%;
  border-radius: 10px;
  text-align: center;
  top: 141px !important;
  left: 5.909% !important;
  padding: 4px;
  font-size: var(--font-size-3xs);
  font-family: 'POPPINS';
  font-weight: 500;
  margin-top: 34px;
  margin-bottom: 5px;
  color: white;
  background-color: var(--oop-concept-background);
}

.div2 {
  
  position: relative;
  height: 57.86%;
  width: 8.5%;
  top: 17%;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  color: black;
  background-color: rgba(255, 255, 255, 0.782);
  padding: 4px;
  left: 30.909% !important;
  font-family: 'POPPINS';
  font-weight: 500;

  font-size: var(--font-size-3xs);

}

.content {
  position: relative;
  width: 650.6px;
  height: 26px;
  box-sizing: content-box;
  display: flex;
  align-items: stretch;
  padding-bottom: 6px;
  background-color: var(--oop-concept-background);
}

.step1 {
  z-index: 0;
  position: absolute;
  /* z-index: 2; */
  top: 44px;
  left: 45px;
  border-radius: 8.72px;
  background-color: #38384c;

  height: 210px;
  width: 650px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /*padding: var(--padding-5xs) var(--padding-xs);*/
  box-sizing: border-box;
  text-align: left;
  font-size: var(--font-size-xs);
  color: #aba9a9;
  font-family: var(--font-roboto);
}

.implement {
  top: 76px;
  left: 245px !important;
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: #FB9521;
  border-radius: 13px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-6xs);
  margin: 100px 270px;
  color: white;
  font-family: "POPPINS";
  font-size: 10px;
  font-weight: 600;
}

.implement:hover {
  background: var(--color-darkorange-onhover);
}