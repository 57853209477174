.tutorialExamples {
  position: absolute;
  top: 0;
  right: 158px;
  display: inline-block;
  width: 279px;
}
.tutorialexamples {
  position: absolute;
  top: 331px;
  left: 685px;
  width: 437px;
  height: 332px;
  text-align: justify;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}

.tutorialcardmainChild {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-mini);
  background: linear-gradient(139.01deg, #8471e0, #652fc5 93.23%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.35);
  width: 1122px;
  height: 277px;
  overflow: hidden;

}
.classesAndObjects {
  position: absolute;
  top: 95px;
  left: 52px;
  font-size: var(--font-size-21xl);
  font-weight: 600;
}
.tutorialPage {
  top: 38px;
  left: 49px;
  font-size: var(--font-size-lg);
  color: var(--color-black);
  overflow: hidden;
}
.c,
.frameChild,
.tutorialPage {
  position: absolute;
  overflow: hidden;
}
.frameChild {
  
  height: 100%;
  width: 65px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-5xs);
  background-color: var(--color-midnightblue-600);
  border: 1.5px solid var(--color-white);
  box-sizing: border-box;
  
}
.c {
  top: 8px;
  left: 65%; /* Set left to 50% */
  transform: translateX(-50%); /* Adjust position horizontally */
  max-width: fit-content;
  position: absolute; /* Assuming this is an absolutely positioned element */
}
.rectangleContainer,
.rectangleGroup,
.rectangleParent {
  position: absolute;
  top: 208px;
  left: 212px;
  width: 51px;
  height: 36px;
  font-family: var(--font-pt-sans);
}
/* .rectangleContainer,
.rectangleGroup {
  left: 52px;
  width: 57px;
}
.rectangleContainer {
  left: 122px;
  width: 75px;
} */
.cloudIcon {
  position: absolute;
  top: 141px;
  left: 587px;
  width: 524.8px;
  height: 136.2px;
  object-fit: cover;
}
.buttonChild {
  height: 100%;
  width: 100%;
  right: 30px;
  border-radius: var(--br-5xs);
  background-color: var(--color-midnightblue-200);
  cursor: pointer;
  /* text-align: middle; */
  justify-content: center;
  color:white;
  font-family: var(--font-poppins);
  font-size:medium;
  font-weight: 600;
}
.buttonChild:active,
.buttonChild:hover {
  background-color: var(--color-darkblue);
}
.button,
.buttonChild,
.enroll {
  position: absolute;

}
.enroll {
  top: 9px;
  left: 40px;
  font-weight: 500;
}
.button {
  top: calc(50% + 63.4px);
  left: 903px;
  width: 132px;
  height: 45px;
  cursor: pointer;
  text-align: center;
  font-size: var(--font-size-xl);
}
.button:active,
.button:hover {
  background-color: var(--color-gray-600); 
} 
.vectorIcon {
  height: 50%;
  width: 30.8%;
  top: 25.2%;
  right: 36%;
  bottom: 24.8%;
  left: 33.2%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
}
.backarrow,
.vectorIcon,
.vectorIcon1 {
  position: absolute;
  overflow: hidden;
}
.vectorIcon1 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  display: none;
}
.backarrow {
  top: 38px;
  left: 18px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.tutorialcardmain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 277.2px;
  display: flex;
  overflow: hidden;
  justify-content: stretch;
  max-width: 100%;
  align-content: space-between;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
