.button,
.icon {
  position: absolute;
  overflow: hidden;
}


.icon {
  top: 5.5px;
  left: 24px;
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.button {
  top: -63px;
  left: 23px;
  border-radius: var(--br-7xs);
  background-color: var(--color-silver);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 173px;
  height: 30px;
}
.myoopproject {
  position: absolute;
  top: -63px;
  left: 30px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 168px;
  height: 27px;
}

.project {
  position: relative;
}
.project {
  width: 231px;
  height: 47px;
  color: var(--white-default);
}

.fileAdd {
  position: relative;
top:60px;
left:60px;
font-weight: 500;
}

.browser,
.file,
.folder2 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}
.file {

  width: 231px;
  height: 46px;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-11xs) var(--padding-5xl);
  gap: var(--gap-5xs);
  cursor: pointer;
  position: relative;
  top:-10px;
  /* Selected effect */

}
.file:hover {
  background-color: var(--white-transparent-10);
}

.file.selected {
  background-color: var(--white-transparent-10);
}
.deleteIcon {
  position: absolute;
  top: 50%;
  left:0px;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
  display: none; /* Initially hide the delete icon */

}
.deleteIcon img {
  width: 10px;
  height: 10px;
  /* Add other styles as needed */
}
.file:hover .deleteIcon {
  display: block; /* Show the delete icon on hover */
}

.browser,
.folder2 {
  position: absolute;
  top: 70px;
  left: 0;
  width: 211px;
  height: 748px;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--padding-5xs) 0;
}
.folder2 {
  top: 80px;
  width: 207px;
  height: 46px;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-11xs) var(--padding-5xl);
  gap: var(--gap-5xs);
  color: var(--white-default);
}
.image14 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 60px;
  left: 26px;
  right: 30px;
  width: 20px;
  height: 20px;
  background-image: url(/public/image-14@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.navigation {
  position: absolute;
  top: 0;
  left: 79px;
  right: 30px;
  background-color: #211f2e;

  /* background: linear-gradient(#160f3a, #160f3a), rgba(51, 51, 51, 0.8); */
  backdrop-filter: blur(50px);
  width: 222px;
  height: 900px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--white-transparent-80);
  font-family: var(--font-poppins);
}
/* FileNavigationContainer.module.css */

.defaultFileNavigation {
  /* default styles */
  position: absolute;
  top: 0;
  left: 79px;
  right: 30px;
background-color: #211f2e;
  /* background: linear-gradient(#160f3a, #160f3a), rgba(51, 51, 51, 0.8); */
  backdrop-filter: blur(50px);
  width: 222px;
  height: 900px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--white-transparent-80);
  font-family: var(--font-poppins);
}

.customFileNavigation {
  /* additional styles for CodeEditor page */
  /* customize the positioning or any other styles as needed */
  
  background-color: #211f2e;

  /* background: linear-gradient(#160f3a, #160f3a), rgba(51, 51, 51, 0.8); */
  backdrop-filter: blur(50px);
  width: 222px;
  height: 900px;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--white-transparent-80);
  font-family: var(--font-poppins);
}

.fileContainer {
  display: flex;
  flex-direction: column;
}
