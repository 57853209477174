.logInChild {
  position: absolute;
  top: 147px;
  left: 852px;
  border-radius: var(--br-xl);
  background: conic-gradient(
    from 51.2deg at 50% 50%,
    #23144e 0deg,
    #462084 133.12deg,
    #2b165a 308.09deg,
    #23144e 360deg,
    #462084 493.12deg
  );
  width: 489px;
  height: 659px;
}
.welcomeToVoop {
  margin-block-start: 0;
  margin-block-end: 1px;
}
.welcomeToVoopContainer1 {
  width: 100%;
}
.welcomeToVoopContainer {
  position: absolute;
  top: 420px;
  left: 246px;
  line-height: 6px;
  display: flex;
  align-items: center;
  width: 353.3px;
}
.empowerYourCoding {
  margin-block-start: 0;
  margin-block-end: 2px;
}
.whereOopMeets {
  margin: 0;
}
.empowerYourCodingContainer {
  position: absolute;
  top: 466px;
  left: 232px;
  font-size: var(--font-size-lg);
  line-height: 6px;
  mix-blend-mode: normal;
}
.subtractIcon {
  position: relative;
  border-radius: var(--br-xl);
  width: 0;
  height: 0;
  object-fit: cover;
}
.text {
  flex: 1;
  position: relative;
  font-weight: 600;
}
.button {
  position: absolute;
  top: 676px;
  left: 954px;
  border-radius: 29.45px;
  background: radial-gradient(50% 50%at 50% 50%, #a27cf3, #683fea);
  box-shadow: 0 0 180px #9917ff, 0 0 1px 4px rgba(255, 255, 255, 0.1),
    0-4px 2px rgba(0, 0, 0, 0.25) inset,
    0 2px 1px rgba(255, 255, 255, 0.25) inset;
  width: 285px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-smi) var(--padding-45xl);
  box-sizing: border-box;
  cursor: pointer;
  font-size: var(--font-size-lgi);

}
.logIn,
.signIn {
  width: 100%;
  height: 1024px;
  display: flex;
  max-width: 100%;

}
.logIn {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background-image: url(/public/-log-in@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.signIn {
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  overflow-x: auto;
  align-content: stretch;
  justify-content: stretch;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}

