.youAreAbout {
  position: absolute;
  top: 66px;
  left: 44px;
  font-weight: 600;
}
.confirm {
  position: relative;
  font-size: var(--font-size-mini);
  font-weight: 600;
  font-family: var(--font-poppins);
  color: var(--color-white);
  text-align: center;
}
.explorebutton {
  cursor: pointer;
  border: 0;
  padding: var(--padding-7xs) var(--padding-2xl);
  background-color: #683fea;
  position: absolute;
  top: 211px;
  left: 183px;
  border-radius: var(--br-5xs);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5) inset;
  width: 145px;
  height: 36px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.pleaseConfirmBefore {
  position: absolute;
  top: 116px;
  left: 72px;
  font-size: var(--font-size-base);
}
.pagecrossIcon {
  position: absolute;
  top: 26px;
  left: 457.5px;
  width: 9px;
  height: 9px;
  cursor: pointer;
}
.confirmEnrollment {
  position: relative;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-200);
  backdrop-filter: blur(20px);
  width: 500px;
  height: 285px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  font-size: 22px;
  color: var(--color-white);
  font-family: var(--font-poppins);
}
