.resetPassword {
  position: absolute;
  top: 0;
  left: 72.5px;
  font-size: var(--font-size-6xl);
  font-weight: 600;
  padding-left: 2 rem;
}
.enterYourNew {
  position: absolute;
  top: 57px;
  left: 91.5px;
  font-weight: 300;
}
.frameforresetpassChild {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 88px;
  left: 7px;
}
.confirmPassword {
  position: absolute;
  top: 158px;
  left: 113px;
  font-weight: 300;
}
.frameforresetpassItem {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 187px;
  left: 7px;
}
.frameforresetpass {
  position: absolute;
  top: 250px;
  left: 91px;
  width: 344px;
  height: 249px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-smi);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
